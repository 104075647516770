/**
 * 登录模块的ajax函数
 */

// 引入设置好的axios
import request from '@/utils/request'


// 登录
export const checkLogin = (data) => {
    return request({
        url: '/admin/logins',
        method: "post",
        data
    })
}

// 修改管理员密码
export const modifyPwd = (data) => {
    return request({
        url: '/admin/resetYourSelves',
        method: "post",
        data
    })
}
