/**
 * ajax工具函数
 */

/* 引入需要的函数 */
import axios from 'axios' // 引入ajax函数
import { Message } from 'element-ui'  // 引入element的消息组件
import md5 from "js-md5"; // 加密算法
import local from '@/utils/local'// 引入local工具函数
import router from '@/router' // 引入路由 自动找到index.js
// import { getNewTokens } from '@/api/common.js'

// 创建一个axios实例 设置请求地址和请求超时时间
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url

    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000000000 // request timeout
})
console.log('baseUrl', process.env.VUE_APP_BASE_API);
// 计算签名
// let get_sign = (config, timestamp) => {
//     var join_way = ["=", "&"];//签名连接方式
//     var sign_array = [];//待签名的数组
//     var sign_str; //签名字符串
//     var data_array = [];//请求的参数的数组形态
//     //假定config.data为对象（Object），如果为json字符串需自行 JSON.parse()解析
//     for (var key in config.data) {
//         var typeofName = typeof (config.data[key]);//获取对象类型
//         var dataValue = config.data[key];//待处理value，默认string、number无需处理
//         if (typeofName == "object") {
//             //对象、数组转为json字符串
//             dataValue = JSON.stringify(dataValue);
//         } else if (typeofName == "boolean") {
//             //布尔转为数字
//             dataValue = dataValue ? 1 : 0;
//         } else if (typeofName == 'string' && dataValue == '') {
//             dataValue = ''
//         }
//         dataValue = md5(dataValue.toString());
//         sign_array.push([key, dataValue].join(join_way[0]));//添加进待签名数组
//     }
//     sign_array.push(["method", md5(config.method.toUpperCase())].join(join_way[0]));//添加请求方式
//     sign_array.push(["timestamp", md5(timestamp.toString())].join(join_way[0]));//添加当前时间
//     sign_array.push(["appkey", md5("123")].join(join_way[0]));//添加密钥
//     sign_str = sign_array.sort().join(join_way[1]);//排序后使用&符进行连接，拼为字符串
//     sign_str = encodeURIComponent(sign_str);//中文、实体字符转义
//     // console.log(sign_str)
//     sign_str = md5(sign_str).toUpperCase();//MD5签名后转大写
//     return sign_str;
// }

// 请求拦截器
service.interceptors.request.use(
    config => {
        // do something before request is sent
        // console.log("请求config", config);

        const timestamp = Date.parse(new Date()) / 1000;    // 当前时间戳
        // config.headers['sign'] = get_sign(config, timestamp);   //  往请求头添加签名
        config.headers['timestamp'] = timestamp;    // 往请求头添加时间戳
        // 从本地取出token
        let token = local.get('token')
        // console.log('token>>>', token)
        if (token) {
            // config.headers['Authorization'] = token // 往请求头添加token
            config.headers['token'] = token // 往请求头添加token
        }
        return config
    },
    error => {
        // do something with request error
        console.log('请求错误', error) // for debug
        return Promise.reject(error)
    }
)
// 标志当前是否正在刷洗token
let isNotRefreshing = true;


// 响应拦截器
service.interceptors.response.use(
    async res => {
        // console.log('响应拦截器>>>', res);

        //我们可以定义一个标准响应体，比如：{code=401,msg='token已过期',data:null}，当收到token过期的响应就要进行token刷新了
        if (res.status == 401) {
            router.push('/login');
        } else {
            if (res.status == 200 || res.status == 422) {
                return res.data;
            } else if (res.status == 403) {
                Message({
                    message: '当前登录已过期, 请重新登录!',
                    type: 'error',
                    duration: 2000
                })
                local.remove("token");
                router.push('/login');
            } else if (res.code) {
                Message({
                    message: res.data.msg || 'Error',
                    type: 'error',
                    duration: 2000
                })

                return Promise.reject(new Error(res.data.msg || 'Error'))
            }
        }

    },

    error => {
        console.log('响应错误' + error) // for debug
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })

        // 如果错误代码是401 证明未授权
        if (error.response.status === 401 || error.response.status === 203) {
            router.push('/login')
        }

        return Promise.reject(error)// 让Promise状态从进行中 -> 失败
    }
)

export default service
