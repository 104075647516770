<template>
  <div class="login">
    <div class="panel">
      <h1 class="title">游鹿鹿心愿瓶后台登录</h1>

      <el-form :model="loginForm" ref="loginForm">
        <!-- 账号 -->
        <el-form-item prop="phone">
          <el-input
            prefix-icon="iconfont icon-account"
            v-model="loginForm.phone"
            clearable
          >
          </el-input>
        </el-form-item>

        <!-- 密码 -->
        <el-form-item prop="password">
          <el-input
            @keydown.native.enter="login"
            v-model="loginForm.password"
            prefix-icon="iconfont icon-password"
            :type="visible ? 'text' : 'password'"
          >
            <i
              slot="suffix"
              class="iconfont"
              :class="visible ? 'icon-eye-open' : 'icon-eye-close'"
              @click="changeType"
            ></i>
          </el-input>
        </el-form-item>

        <el-form-item class="panel-item">
          <el-button type="primary" @click="login" class="login-btn">
            登录
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
// 引入ajax函数
import { checkLogin } from "../../api/login";
// 引入工具函数
import local from "@/utils/local";
import md5 from "js-md5";

export default {
  // 数据
  data() {
    return {
      // 登录表单数据
      loginForm: {
        phone: "",
        password: "",
      },
      // 验证规则
      /* rules: {
        phone: [{ required: true, validator: checkAccount, trigger: "blur" }],
        password: [{ required: true, validator: checkPass, trigger: "blur" }],
      }, */

      // 是否显示密码
      visible: false,
    };
  },

  // 方法
  methods: {
    // 点击切换密明文
    changeType() {
      this.visible = !this.visible;
    },

    // 登录
    async login() {
      let flag = true;
      if(!this.loginForm.phone){
        flag = false
        this.$message.error('请填写账号名！');
      }else if(!this.loginForm.password){
        flag = false
        this.$message.error('请填写密码！');
      }

      if (flag) {
        let data = {
          username: this.loginForm.phone.trim(),
          password: md5(this.loginForm.password.trim()),
        };
        // 发送ajax
        let res = await checkLogin(data);
        // console.log("登录", res);
        // 结构对象;
        let { code } = res;

        if (code === 200) {
          // 将token存入本地
          local.set("token", res.data.token);
          // 将用户信息存入本地
          //   local.set('userInfo', res.data.merchant)
          // 跳转到首页
          this.$router.push("/wishlist");
        } else {
          this.$message.error(res.msg);
          return;
        }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  // background: url("../../assets/imgs/login.png") no-repeat;
  // background-size: cover;
  background: #2d3a4b;
  display: flex;
  align-items: center;
  justify-content: center;

  .panel {
    width: 420px;
    background: #fff;
    padding: 50px;
    border-radius: 20px;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .el-form-item {
      margin-top: 20px;
    }

    .el-button {
      width: 100%;
    }

    .el-button--primary {
      background-color: #1890ff !important;
      border-color: #1890ff !important;
      border-radius: 26px !important;
    }
  }
}
</style>