import Vue from 'vue'
import VueRouter from 'vue-router'
// 引入local工具函数
import local from '@/utils/local'

// 引入页面
import Login from '../views/login/Login.vue'
// import Home from '../views/home/home.vue'
import Layout from '../views/layout/Layout.vue'



Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const routes = [

    /*  {
      // 无二级路由配置示例
       path: '/shop',
       component: Layout,
       meta: { title: "店铺管理" },
       children: [
         { path: '', component: Shop }
       ]
     }, */


    // 根目录重定向到登录页面
    { path: '/', redirect: '/home' },
    // { path: '/', component: Home },
    {
        path: '/home',
        component: Layout,
        meta: { title: "首页" },
        children: [
            { path: '', name: 'home', component: () => import('../views/home/home.vue') }
        ]
    },


    // 登录
    { path: '/login', component: Login },

    // 心愿管理
    {
        path: '/wishlist',
        component: Layout,
        meta: { title: "心愿管理" },
        children: [
            { path: '', name: 'wishlist', component: () => import('../views/wishBottleList/WishBottleList.vue') }
        ]
    },

    // 分项查询
    {
        path: '/itemizedQuery/:name',
        component: Layout,
        meta: { title: "分项查询" },
        children: [
            { path: '', name: 'itemizedQuery', component: () => import('../views/itemizedQuery/index.vue') }
        ]
    },

    // 修改密码
    {
        path: '/pwdmodify',
        component: Layout,
        meta: { title: "修改密码" },
        children: [
            { path: '', component: () => import('../views/account/PasswordModify.vue') }
        ]
    },

    // 小程序配置
    {
        path: '/miniprogram',
        component: Layout,
        meta: { title: "小程序配置" },
        children: [
            { path: '', component: () => import('../views/miniProgram/MiniProgram.vue') }
        ]
    },

    {
        path: '/test',
        component: Layout,
        meta: { title: "测试" },
        children: [
            { path: '', component: () => import('../views/test/index.vue') }
        ]
    },


    // 多路径配置示例
    // {
    //     path: '/account',
    //     component: Layout,
    //     redirect: '/account/list',
    //     meta: { title: "账号管理" },
    //     children: [
    //         // { path: '/account/list', component: AccountList, meta: { title: "账号列表" }, },
    //         // { path: '/account/add', component: AccountAdd, meta: { title: "账号添加" }, },
    //         { path: '/account/passwordmodify', component: () => import('@/views/account/PasswordModify.vue'), meta: { title: "个人中心" }, },
    //         // { path: '/account/personal', component: () => import('@/views/account/Personal.vue'), meta: { title: "个人中心" }, },
    //     ]
    // },

]

const router = new VueRouter({
    routes
})

/* 全局路由前置守卫 */
router.beforeEach((to, from, next) => {
    // to: 要去哪里, 包含路由信息
    // from: 来自哪里, 包含路由信息
    // next: 是一个函数, 用于放行
    // console.log("我是路由保卫");

    // 查看本地是否有令牌, 有-代表登陆过, 没有-代表没有登陆过
    // let isLogin = local.get('token') ? true : false
    next()

    // 判断是否登录过
    // if (isLogin) {  // 登录过-直接放行
    //     next()
    // } else {    // 没有登录过-判断是否是去登录页面
    //     if (to.path === '/login') { // 去登录页面-直接放行
    //         next()
    //     } else {  // 不是去登录页面-跳转到登录页面
    //         next({ path: "/login" })
    //     }
    // }
})

export default router
