<template>
  <div class="layout scrollbar">
    <!-- 顶部的导航 -->
    <top-nav></top-nav>

    <!-- 出口 -->
    <div class="output">
      <router-view></router-view>
    </div>

    <!-- 底部信息 -->
    <footer-info></footer-info>
  </div>
</template>

<script>
// 引入组件
import TopNav from "./TopNav.vue";
import FooterInfo from "./FooterInfo.vue";

export default {
  components: {
    TopNav,
    FooterInfo,
  },
};
</script>

<style lang="less" scoped>
.layout {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  .output {
    background-color: #f9f9f9;
  }
}
</style>
