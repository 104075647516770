import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入重置样式
import './assets/css/normalize.css'
import './assets/css/reset.css'
import './assets/css/common.css'

// 引入iconfont.css
import './assets/fonts/iconfont.css'

// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts';

// 引入elemen-ui的js和css
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

// 引入rem自适应的js
import '@/utils/rem.js'

// 在vue中注入ElementUI
Vue.use(ElementUI)

// 将echarts挂在原型上
Vue.prototype.$echarts = echarts


// 创建乱传的中介
Vue.prototype.$bus = new Vue()

Vue.config.productionTip = false  //关闭生产提示-就是一些没什么用的警告信息

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
