<template>
  <div class="top_nav">
    <div class="container">
      <div
        class="logo_wrapper cursorP"
        @click.stop="jumpToPage({ path: 'home', name: '首页' })"
      >
        <img
          class="icon_logo"
          src="https://www.jzzz888.com/upload/logo_round.png"
          alt=""
        />
        <div class="name primaryColor">资质帮</div>
      </div>

      <div class="menu">
        <div
          class="menu_itme"
          v-for="(item, index) in menuList"
          :key="item.name"
          @mouseover="handleMenuHover(index, 'over')"
          @click="jumpToPage(item)"
        >
          <div class="item mr5">{{ item.name }}</div>
          <i v-if="item.children.length > 0" class="el-icon-arrow-down"></i>

          <div
            class="menu_child"
            v-if="item.children.length > 0 && item.hover"
            @mouseleave="handleMenuHover(index, 'leave')"
          >
            <div
              class="child_item"
              v-for="child in item.children"
              :key="child.name"
              @click.stop="jumpToPage(child)"
            >
              <img class="icon" :src="child.iconUrl" alt="" />
              <div class="text">{{ child.name }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="vip cursorP">
        <img
          class="icon_vip"
          src="https://www.jzzz888.com/upload/static/web/huiyuan.png"
          alt=""
        />
        <span>会员服务</span>
      </div>

      <img
        class="icon_phone cursorP"
        src="https://www.jzzz888.com/upload/static/web/shouji.png"
        alt=""
      />

      <div class="feature flex alc cursorP">
        <span class="mr5">功能导航</span>
        <i class="el-icon-caret-bottom"></i>
      </div>

      <div class="user ml20 cursorP">王麻子</div>
    </div>
  </div>
</template>

<script>
export default {
  // 数据
  data() {
    return {
      menuList: [
        { name: "首页", path: "home", hover: false, children: [] },
        {
          name: "分项查询",
          hover: false,
          path: "itemizedQuery",
          children: [
            {
              name: "查企业",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/查企业.png",
              hover: false,
            },
            {
              name: "查业绩",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/查业绩.png",
              hover: false,
            },
            {
              name: "查资质",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/查资质.png",
              hover: false,
            },
            {
              name: "项目经理",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/项目经理.png",
              hover: false,
            },
            {
              name: "技术负责人",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/技术负责人.png",
              hover: false,
            },
            {
              name: "查人员",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/查人员.png",
              hover: false,
            },
            {
              name: "查招标",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/查招标.png",
              hover: false,
            },
            {
              name: "查荣誉",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/rongyu.png",
              hover: false,
            },
            {
              name: "查诚信",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/chengxin.png",
              hover: false,
            },
            {
              name: "查评价",
              path: "itemizedQuery",
              iconUrl: "https://www.jzzz888.com/upload/static/pingjia.png",
              hover: false,
            },
          ],
        },
        { name: "组合查询", path: "", hover: false, children: [] },
        { name: "四库专查", path: "", hover: false, children: [] },
        { name: "公路库专查", path: "", hover: false, children: [] },
        { name: "商机联系", path: "", hover: false, children: [] },
        { name: "研究院", path: "", hover: false, children: [] },
      ],
    };
  },
  // 计算属性
  computed: {
    // curActive() {
    //   return this.$route.path;
    // },
  },
  // 生命周期-实例创建完成
  created() {},
  // 方法
  methods: {
    // 菜单hover事件
    handleMenuHover(index, flag) {
      if (flag == "over") {
        this.menuList[index].hover = true;
      } else {
        this.menuList[index].hover = false;
      }
    },
    // 页面跳转
    jumpToPage(item) {
      const path = item.path;
      if (path) {
        this.$router.push({ name: path, params: { name: item.name } });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.top_nav {
  background-color: #d7edfb;
  position: sticky;
  top: 0;
  z-index: 10;

  .container {
    max-width: 1200px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 0px;
    font-size: 16px;
    color: #333;

    .logo_wrapper {
      display: flex;
      align-items: center;
      .icon_logo {
        width: 34px;
        height: 34px;
        margin-right: 10px;
      }

      .name {
        font-size: 26px;
        font-weight: 500;
      }
    }

    .menu {
      flex: 1;
      display: flex;
      align-items: center;

      .menu_itme {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: relative;

        .menu_child {
          position: absolute;
          top: 38px;
          left: 0px;
          z-index: 100;
          width: 360px;
          background-color: #fff;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          border-radius: 10px;
          box-shadow: 0px 0px 4px 0px rgba(153, 153, 153, 0.5);

          .child_item {
            width: 33.333333%;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 20px;

            .icon {
              width: 40px;
              height: 40px;
              margin-bottom: 5px;
            }
          }
          :hover {
            background-color: #d7edfb;
            border-radius: 6px;
          }
        }
      }
    }

    .vip {
      display: flex;
      align-items: center;
      padding: 5px 10px;
      color: #5a2a00;
      background: linear-gradient(90deg, #ffe4a1 0%, #f3ca90 100%);
      border-radius: 8px;
      line-height: 22px;

      .icon_vip {
        width: 20px;
        height: 18px;
        margin-right: 5px;
      }
    }

    .icon_phone {
      width: 18px;
      height: 24px;
      margin: 0px 24px;
    }

    .feature {
      .el-icon-caret-bottom {
        color: #333;
      }
    }
  }
}
</style>
